export const getBonusPriceLabel = (bonusPriceTradeIn: string | undefined, bonusPriceFinance: string | undefined) => {
    if (bonusPriceTradeIn === 'Ja' && bonusPriceFinance === 'Ja') {
        return 'bei Eintausch und Finanzierung'
    } else if (bonusPriceTradeIn === 'Ja' && bonusPriceFinance !== 'Ja') {
        return 'bei Eintausch'
    } else if (bonusPriceTradeIn !== 'Ja' && bonusPriceFinance === 'Ja') {
        return 'bei Finanzierung'
    }
    return undefined
}
