import React, { FunctionComponent } from 'react'
import { SearchResult } from '@bbx/search-journey/common/SearchResult'
import { Box } from '@wh-components/core/Box/Box'
import { css } from 'styled-components'
import { LinkVomit } from '@bbx/search-journey/common/components/LinkVomit/LinkVomit'
import { PossibleValueLinkVomit } from '../../../../common/common/PossibleValueLinkVomit/PossibleValueLinkVomit'
import { getMakePossibleValues } from '@bbx/search-journey/sub-domains/search/components/common/common/PossibleValueLinkVomit/PossibleValueLinkVomit.helper'
import {
    containsAnyPossibleValue,
    getFlatPossibleValues,
    getNavigatorById,
} from '@bbx/search-journey/sub-domains/search/lib/navigator-functions'

export interface AutoMotorLinkVomitProps {
    currentSearchResult: SearchResult
}

export const AutoMotorLinkVomit: FunctionComponent<AutoMotorLinkVomitProps> = ({ currentSearchResult }) => {
    const makePossibleValues = getMakePossibleValues(currentSearchResult.searchId)
    const showMakeLinkVomit = makePossibleValues && makePossibleValues.length > 0
    const modelNavigator = getNavigatorById(currentSearchResult, 'model')
    const modelPossibleValues = modelNavigator ? getFlatPossibleValues(modelNavigator) : undefined
    const hasModelSelected = modelNavigator ? modelNavigator.selectedValues.length > 0 : false
    const showModelLinkVomit =
        !!modelNavigator && containsAnyPossibleValue(modelNavigator) && !hasModelSelected && !!modelPossibleValues?.[0]?.webLink

    return (
        <Box
            paddingBottom={{ phone: 'm', tablet: 0 }}
            borderTop={{ phone: 'owl', tablet: 0 }}
            css={css`
                > *:last-child {
                    padding-bottom: 0;
                }
            `}
        >
            {currentSearchResult.seoMetaData?.linkGroups && currentSearchResult.seoMetaData.linkGroups.length > 0 && (
                <Box paddingVertical={{ tablet: 'm' }} borderTop={{ tablet: 'owl' }}>
                    <LinkVomit linkGroups={currentSearchResult.seoMetaData.linkGroups} alignLinks="column" />
                </Box>
            )}
            {showMakeLinkVomit && (
                <Box paddingVertical={{ tablet: 'm' }} borderTop={{ tablet: 'owl' }}>
                    <PossibleValueLinkVomit title="Alle Marken" possibleValues={makePossibleValues} testId="make-vomit" />
                </Box>
            )}
            {showModelLinkVomit && (
                <Box paddingVertical={{ tablet: 'm' }} borderTop={{ tablet: 'owl' }}>
                    <PossibleValueLinkVomit
                        title={`${currentSearchResult.searchTitle} Modelle`}
                        possibleValues={modelPossibleValues ?? []}
                        testId="model-vomit"
                    />
                </Box>
            )}
        </Box>
    )
}
