import React, { FunctionComponent } from 'react'
import { css } from 'styled-components'
import { AttributesList, getAttributeValue } from '@wh/common/chapter/types/Attributes'
import { getSellerType, SellerType } from '@bbx/common/lib/getSellerType'
import { Box } from '@wh-components/core/Box/Box'
import { Text } from '@wh-components/core/Text/Text'
import { ResponsiveImage } from '@wh-components/core/ResponsiveImage/ResponsiveImage'

interface AdRowOrganisationLineProps {
    id: string
    logo?: string
    attributes: AttributesList
}

export const AdRowOrganisationLine: FunctionComponent<AdRowOrganisationLineProps> = ({ attributes, logo, id }) => {
    const name = getSellerName(attributes)
    const postCode = getAttributeValue(attributes.attribute, 'POSTCODE')
    const postalName = getAttributeValue(attributes.attribute, 'LOCATION')
    const country = getAttributeValue(attributes.attribute, 'COUNTRY')
    const address = [postCode, country === 'Österreich' ? postalName : country].filter(Boolean).join(' ')

    return (
        <Box display="flex">
            {logo && (
                <ResponsiveImage
                    src={logo}
                    alt={`${name ?? 'Händler'} Logo`}
                    display={{ phone: 'none', tablet: 'block' }}
                    maxHeight={30}
                    maxWidth={120}
                    marginRight="s"
                    marginBottom="xxs"
                    flex="0 0 auto"
                    alignSelf="flex-end"
                    testId={`search-result-entry-seller-logo-${id}`}
                    objectFit="contain"
                    css={css`
                        font-size: ${(p) => p.theme.fontSizes.s};
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    `}
                />
            )}
            <Box display="flex" flexDirection="column" overflow="hidden">
                {name && (
                    <Text
                        fontSize={{ phone: 'xs', tablet: 's' }}
                        color="palette.raccoon"
                        truncate={true}
                        testId={`search-result-entry-seller-information-${id}`}
                    >
                        {name}
                    </Text>
                )}
                {address && (
                    <Text
                        fontSize={{ phone: 'xs', tablet: 's' }}
                        color="palette.raccoon"
                        truncate={true}
                        testId={`search-result-entry-location-${id}`}
                        aria-label={`Wird verkauft in ${address}`}
                    >
                        {address}
                    </Text>
                )}
            </Box>
        </Box>
    )
}

const getSellerName = (attributes: AttributesList) => {
    const sellerType = getSellerType(attributes)

    switch (sellerType) {
        case SellerType.PRIVATE:
            return 'Privat'
        case SellerType.COMMERCIAL:
        case SellerType.DEALER:
            return getAttributeValue(attributes.attribute, 'ORGNAME') || 'Händler'
        default:
            return undefined
    }
}
