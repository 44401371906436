import { AdvertStatus } from '@bbx/common/types/ad-detail/AdvertDetails'
import { FormattedAttributesList, getAttributeValue, getFormattedAttributeValue } from '@wh/common/chapter/types/Attributes'
import { getPriceAttribute, getPriceByStatus } from '@bbx/search-journey/common/lib/getAdvertPrice'
import { getBonusPriceLabel } from '@bbx/search-journey/common/lib/getBonusPriceLabel'

export const getAutoMotorAdvertPrice = (adStatus: AdvertStatus | null, formattedAttributes: FormattedAttributesList) => {
    const price = getFormattedAttributeValue(formattedAttributes.attribute, 'PRICE_FOR_DISPLAY') || 'Keine Angabe'

    const netPrice = getPriceAttribute(formattedAttributes.attribute, 'MOTOR_PRICE/NET')
    const bonusPriceTradeIn = getAttributeValue(formattedAttributes.attribute, 'MOTOR_PRICE_BONUS/TRADE_IN')
    const bonusPriceFinance = getAttributeValue(formattedAttributes.attribute, 'MOTOR_PRICE_BONUS/FINANCE')
    const bonusPrice = getPriceAttribute(formattedAttributes.attribute, 'MOTOR_PRICE/BONUS')
    const leasingPrice = getPriceAttribute(formattedAttributes.attribute, 'LEASING/MONTHLY_RATE')

    if (bonusPrice) {
        bonusPrice.label = getBonusPriceLabel(bonusPriceTradeIn, bonusPriceFinance)
    }

    return {
        mainPrice: getPriceByStatus({ label: 'Verkaufspreis', value: price }, adStatus),
        additionalPrices: [leasingPrice, bonusPrice, netPrice].filter((p) => Boolean(p.value)).slice(0, 1),
    }
}
