import React, { FunctionComponent } from 'react'
import {
    isMultiSelectNavigator,
    isRangeNavigator,
    isSingleSelectNavigator,
    isTextNavigator,
} from '@bbx/search-journey/sub-domains/search/lib/navigator-functions'
import { NavigatorProps } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/NavigatorProps'
import { MultiSelectNavigator } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/MultiSelectNavigator/MultiSelectNavigator'
import { EquipmentNavigator } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/MultiSelectNavigator/EquipmentNavigator'
import { GroupedModelNavigators } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/MultiSelectNavigator/GroupedModelNavigators'
import { AutoColorNavigator } from '@bbx/search-journey/sub-domains/search/components/verticals/auto-motor/result-list/Navigators/AutoColorNavigator'
import { EngineEffectNavigator } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/RangeNavigatorComponent/EngineEffectNavigator'
import { KeywordNavigator } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/KeywordNavigator/KeywordNavigator'
import { RangeNavigatorComponent } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/RangeNavigatorComponent/RangeNavigatorComponent'
import { MakeModalNavigatorWithFakeModel } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/MultiSelectNavigator/MakeModalNavigatorWithFakeModel'
import { ProvinceNavigator } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/ProvinceNavigator/ProvinceNavigator'
import { Navigator, RangeNavigator } from '@bbx/search-journey/common/Navigators'
import { verticals } from '@wh/common/chapter/types/verticals'
import { SearchIdNavigator } from '@bbx/search-journey/sub-domains/search/components/common/result-list/SearchIdNavigator/SearchIdNavigator'

export const AutoMotorNavigatorDiscriminator: FunctionComponent<
    NavigatorProps & { abortRequest: () => void; districtNavigator: Navigator | RangeNavigator | undefined }
> = ({ navigator, districtNavigator, onSearch, abortRequest, searchId, 'aria-labelledby': ariaLabelledBy, taggingData }) => {
    if (isTextNavigator(navigator)) {
        return (
            <KeywordNavigator
                navigator={navigator}
                onSearch={onSearch}
                abortRequest={abortRequest}
                aria-labelledby={ariaLabelledBy}
                taggingData={taggingData}
            />
        )
    } else if (isSingleSelectNavigator(navigator)) {
        if (navigator.id === 'searchId') {
            return <SearchIdNavigator navigator={navigator} onSearch={onSearch} taggingData={taggingData} />
        } else {
            return (
                <MultiSelectNavigator
                    navigator={navigator}
                    onSearch={onSearch}
                    aria-labelledby={ariaLabelledBy}
                    taggingData={taggingData}
                />
            )
        }
    } else if (isMultiSelectNavigator(navigator)) {
        if (navigator.id === 'make') {
            return (
                <MakeModalNavigatorWithFakeModel
                    navigator={navigator}
                    onSearch={onSearch}
                    searchId={searchId}
                    aria-labelledby={ariaLabelledBy}
                    taggingData={taggingData}
                />
            )
        } else if (navigator.id === 'model') {
            return (
                <GroupedModelNavigators
                    navigator={navigator}
                    onSearch={onSearch}
                    aria-labelledby={ariaLabelledBy}
                    taggingData={taggingData}
                />
            )
        } else if (navigator.id === 'equipment') {
            return (
                <EquipmentNavigator navigator={navigator} onSearch={onSearch} aria-labelledby={ariaLabelledBy} taggingData={taggingData} />
            )
        } else if (navigator.id === 'color') {
            return (
                <AutoColorNavigator navigator={navigator} onSearch={onSearch} aria-labelledby={ariaLabelledBy} taggingData={taggingData} />
            )
        } else if (navigator.id === 'district') {
            return null
        } else if (navigator.id === 'region') {
            return (
                <ProvinceNavigator
                    navigator={navigator}
                    districtNavigator={districtNavigator}
                    onSearch={onSearch}
                    label="Suche in Region"
                    aria-labelledby={ariaLabelledBy}
                    taggingData={taggingData}
                    taggingEvent="search_result_list_show_all_regions"
                    vertical={verticals.MOTOR}
                />
            )
        } else {
            return (
                <MultiSelectNavigator
                    navigator={navigator}
                    onSearch={onSearch}
                    aria-labelledby={ariaLabelledBy}
                    taggingData={taggingData}
                />
            )
        }
    } else if (isRangeNavigator(navigator)) {
        if (navigator.id === 'engineeffect') {
            return <EngineEffectNavigator navigator={navigator} onSearch={onSearch} abortRequest={abortRequest} taggingData={taggingData} />
        } else {
            return (
                <RangeNavigatorComponent
                    navigator={navigator}
                    onSearch={onSearch}
                    abortRequest={abortRequest}
                    taggingData={taggingData}
                    formatInput={navigator.id !== 'yearmodel'}
                />
            )
        }
    } else {
        return null
    }
}
