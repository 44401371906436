import React, { FunctionComponent } from 'react'
import { AdvertSummary } from '@bbx/common/types/ad-detail/AdvertSummary'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import { ResultListAdInMotionLayout } from '@bbx/search-journey/sub-domains/search/components/common/result-list/AdRow/ResultListAdInMotionLayout'
import { formatAttributes } from '@bbx/common/lib/formattingAttributes'
import { AdRowAttributesLine } from './AdRowAttributesLine'
import { AdRowOrganisationLine } from './AdRowOrganisationLine'
import { getAutoMotorAdvertPrice } from '@bbx/search-journey/common/lib/getAutoMotorAdvertPrice'

interface AutoMotorAdInMotionProps {
    advertSummary: AdvertSummary
    taggingData: TaggingData
    className?: string
}

export const AutoMotorAdInMotion: FunctionComponent<AutoMotorAdInMotionProps> = ({ advertSummary, taggingData, className }) => {
    const formattedAttributes = formatAttributes(advertSummary.attributes)
    const { mainPrice } = getAutoMotorAdvertPrice(advertSummary.advertStatus, formattedAttributes)

    return (
        <ResultListAdInMotionLayout
            advertSummary={advertSummary}
            taggingData={taggingData}
            heading={advertSummary.description}
            subHeader={<AdRowAttributesLine id={advertSummary.id} adTypeId={advertSummary.adTypeId} attributes={formattedAttributes} />}
            teaserAttributes={advertSummary.teaserAttributes}
            footer={
                <AdRowOrganisationLine
                    id={advertSummary.id}
                    logo={advertSummary.upsellingOrganisationLogo || undefined}
                    attributes={formattedAttributes}
                />
            }
            price={mainPrice}
            className={className}
        />
    )
}
