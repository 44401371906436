import { SearchResult } from '@bbx/search-journey/common/SearchResult'
import React, { FunctionComponent } from 'react'
import { AdvertSummary } from '@bbx/common/types/ad-detail/AdvertSummary'
import { getAttributeValue } from '@wh/common/chapter/types/Attributes'
import { ResultListAdInMotionSkeleton } from '@bbx/search-journey/sub-domains/search/components/common/result-list/AdRow/ResultListAdInMotionSkeleton'
import { css } from 'styled-components'
import { scrollMargin } from '@bbx/search-journey/sub-domains/search/components/common/result-list/AdRow/ResultListAdRows'
import { ResultListAdRowSkeleton } from '@bbx/search-journey/sub-domains/search/components/common/result-list/AdRow/ResultListAdRowSkeleton'
import { AdRowDynamicRendering } from '@bbx/search-journey/sub-domains/search/components/common/result-list/AdRowDynamicRendering'
import { AutoMotorAdInMotion } from '@bbx/search-journey/sub-domains/search/components/verticals/auto-motor/result-list/AdRow/AutoMotorAdInMotion'
import { AutoMotorAdRow } from '@bbx/search-journey/sub-domains/search/components/verticals/auto-motor/result-list/AdRow/AutoMotorAdRow'
import { baseFontSize } from '@wh-components/core/theme/fontSizeHelper'

export const InternalAutoMotorAdRowWrapper: FunctionComponent<{
    currentSearchResult: SearchResult
    isLoading: boolean
    index: number
    advertSummary: AdvertSummary
}> = ({ currentSearchResult, isLoading, index, advertSummary }) => {
    const adInMotionHeight = { phone: 420, tablet: 476, desktop: 514 }
    return getAttributeValue(advertSummary.attributes.attribute, 'UPSELLING_RESULT_LIST_AD_IN_MOTION') ? (
        <AdRowDynamicRendering index={index} id={advertSummary.id} nrOfAds={5} height={adInMotionHeight}>
            {isLoading ? (
                <ResultListAdInMotionSkeleton
                    height={adInMotionHeight}
                    css={css`
                        ${scrollMargin}
                    `}
                />
            ) : (
                <AutoMotorAdInMotion
                    advertSummary={advertSummary}
                    taggingData={currentSearchResult.taggingData}
                    css={css`
                        ${scrollMargin}
                    `}
                />
            )}
        </AdRowDynamicRendering>
    ) : (
        <AdRowDynamicRendering
            index={index}
            id={advertSummary.id}
            nrOfAds={5}
            height={{ phone: 214.25, tablet: 233, desktop: `${182 / baseFontSize}rem` }}
        >
            {isLoading ? (
                <ResultListAdRowSkeleton
                    estimatedPhoneTitleLines={1}
                    css={css`
                        ${scrollMargin}
                    `}
                />
            ) : (
                <AutoMotorAdRow
                    advertSummary={advertSummary}
                    taggingData={currentSearchResult.taggingData}
                    css={css`
                        ${scrollMargin}
                    `}
                />
            )}
        </AdRowDynamicRendering>
    )
}

export const AutoMotorAdRowWrapper = React.memo(InternalAutoMotorAdRowWrapper)
