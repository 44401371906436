import { TopAdConfig, topAdAddressConfig } from '../../../../common/result-list/TopAds/topAdConfig'
import { formatNumber } from '@wh/common/chapter/lib/formatter'

const topAdMotorConfig: TopAdConfig = {
    teaserAttributes: ['YEAR_MODEL', 'MILEAGE'],
    buildStringFromTeaserItems: ([yearModel, mileage]) => {
        const teaserAttributes: string[] = []
        if (yearModel) {
            const formattedYearModel = `${yearModel.values[0]} ${yearModel.postfix}`
            teaserAttributes.push(formattedYearModel)
        }
        if (mileage) {
            const formattedMileage = `${formatNumber(mileage.values[0])} ${mileage.postfix}`
            teaserAttributes.push(formattedMileage)
        }
        return teaserAttributes.join(', ')
    },
    priceAttributes: ['PRICE_FOR_DISPLAY'],
    ...topAdAddressConfig,
}

type MotorAdType = 'MotorCar' | 'MotorMc' | 'MotorVanTruck' | 'MotorCaravan'

export const topAdMotorConfigMap: Record<MotorAdType, TopAdConfig> = {
    MotorCar: topAdMotorConfig,
    MotorMc: topAdMotorConfig,
    MotorVanTruck: topAdMotorConfig,
    MotorCaravan: topAdMotorConfig,
}
