import { getAttributeValue, AttributesList } from '@wh/common/chapter/types/Attributes'

export type SellerType = (typeof SellerType)[keyof typeof SellerType]

export const SellerType = {
    DEALER: 'dealer',
    PRIVATE: 'private',
    COMMERCIAL: 'commercial',
} as const

export const getSellerType = (advertAttibutes: AttributesList) => {
    const isPrivate = getAttributeValue(advertAttibutes.attribute, 'ISPRIVATE') === '1'

    if (isPrivate) {
        return SellerType.PRIVATE
    }

    const isWillhabenUser = getAttributeValue(advertAttibutes.attribute, 'ORG_TYPE') === '2'
    return isWillhabenUser ? SellerType.COMMERCIAL : SellerType.DEALER
}
