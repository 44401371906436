import React, { FunctionComponent } from 'react'
import { AttributesList, getAttributeValue } from '@wh/common/chapter/types/Attributes'
import { Text } from '@wh-components/core/Text/Text'
import { calculatePsValue } from '@bbx/common/lib/CalculatePsValue'
import { adTypeIdMap } from '@wh/common/chapter/types/AdType'

interface AdRowAttributesLineProps {
    id: string
    adTypeId: number
    attributes: AttributesList
}

export const AdRowAttributesLine: FunctionComponent<AdRowAttributesLineProps> = ({ adTypeId, attributes, id }) => {
    const subheaderText = getSubHeaderText(adTypeId, attributes)

    return (
        <Text fontSize="s" color="palette.raccoon" truncate={true} testId={`search-result-entry-subheader-${id}`}>
            {subheaderText}
        </Text>
    )
}

const getSubHeaderText = (adTypeId: number, attributes: AttributesList) => {
    const conditionReport = getAttributeValue(attributes.attribute, 'CONDITION_REPORT') === '1' ? 'gültiges Pickerl' : undefined
    const defectsLiability = getAttributeValue(attributes.attribute, 'DEFECTS_LIABILITY') === '1' ? 'Gewährleistung' : undefined
    const engineEffectKw = getAttributeValue(attributes.attribute, 'ENGINE/EFFECT')
    const engineEffectPs = engineEffectKw && calculatePsValue(engineEffectKw)
    const engineEffectString = engineEffectKw && `${engineEffectPs} PS (${engineEffectKw} kW)`
    const fuel = getAttributeValue(attributes.attribute, 'ENGINE/FUEL_RESOLVED')
    const mc_category = getAttributeValue(attributes.attribute, 'MC_CATEGORY_RESOLVED')
    const transmission = getAttributeValue(attributes.attribute, 'TRANSMISSION_RESOLVED')
    const warranty = getAttributeValue(attributes.attribute, 'WARRANTY') === '1' ? 'Garantie' : undefined

    switch (adTypeId) {
        case adTypeIdMap.MOTOR_MC:
            return [mc_category, engineEffectString, conditionReport, defectsLiability, warranty].filter(Boolean).join(', ')
        case adTypeIdMap.MOTOR_CARAVAN:
            return [conditionReport, transmission, defectsLiability, warranty].filter(Boolean).join(', ')
        default:
            return [fuel, transmission, conditionReport, defectsLiability, warranty].filter(Boolean).join(', ')
    }
}
